import React, { useState } from 'react';
import axios from 'axios';
import logo from './../assets/images/logo.svg';
import linkedinIcon from './../assets/icons/mdi_linkedin.svg';
import twitterIcon from './../assets/icons/prime_twitter.svg';
import instagramIcon from './../assets/icons/ri_instagram-fill.svg';
import './../App.css';

function Home() {
  const [email, setEmail] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const hubspotData = new URLSearchParams();
    hubspotData.append('email', email);

    console.log('Submitting form with email:', email);

    try {
        const response = await axios.post(
            `https://forms.hubspot.com/uploads/form/v2/47055314/9a8c5201-3616-4ce2-b6ff-5f4e23248e64`,
            hubspotData,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );

        console.log('Response received:', response);

        if (response.status === 200 || response.status === 204) {
            console.log('Form submission successful');
            setIsSubmitted(true);
        } else {
            console.log('Form submission failed with status:', response.status);
        }
    } catch (error) {
        console.error('Error submitting the form:', error);
    }
};


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="soctial-container">
  <a href="https://x.com/flyzen1897" className="sotial-btn" target="_blank" rel="noopener noreferrer">
    <img alt='twitter' src={twitterIcon}></img>
  </a>
  <a href="https://www.linkedin.com/company/flyzenapp/" className="sotial-btn" target="_blank" rel="noopener noreferrer">
    <img alt='linkedin' src={linkedinIcon}></img>
  </a>
  <a href="https://www.instagram.com/flyzen.app/" className="sotial-btn" target="_blank" rel="noopener noreferrer">
    <img alt='instagram' src={instagramIcon}></img>
  </a>
</div>

        <h1 className="Heading">Join our beta and fly with your zen</h1>
        <p className='secondary'>Calm your mind and ease your fear of flying with our guided meditations and expert tips. Find peace in the skies with FlyZen.</p>

        <div className="email-signup">
          {isSubmitted ? (
            <div className="thank-you-message">
              <h2 className='secondary-for-thankyou'>Thank you for subscribing!</h2> 
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="formcontainer">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-input"
                required
              />
              <button type="submit" className="submit-button">Send</button>
            </form>
          )}
        </div>

        <div className="note-container">
          <p className="note">
            <span role="img" aria-label="crown">👑</span> First <span className="highlight">1000 users</span> will receive a <span className="highlight">1 Year Free</span> access to all upcoming features!
          </p>
        </div>
        <p className="Copyrights">©Copyrights 2024. FlyZen Inc.</p>
      </header>
    </div>
  );
}

export default Home;

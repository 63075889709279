import React, { useEffect } from 'react';

const Auth: React.FC = () => {
  useEffect(() => {
    // Attempt to close the window (may work on desktop browsers)
    window.close();
    // Redirect to the app using the custom URL scheme directly to the /(tabs) route
    window.location.href = 'myapp:///(tabs)';
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h1>Authentication Successful</h1>
      <p>You will be redirected back to the app automatically.</p>
      <p>
        If the app doesn't open automatically, <a href="myapp:///(tabs)">click here</a> to continue.
      </p>
    </div>
  );
};

export default Auth;
